import React from 'react'

import NoLayout from '../components/layout/nolayout'

import RequestInviteForm from '../components/requestinvite/requestinviteForm'

import '../components/requestinvite/requestinvite.css'

const RequestInvitePage = () => (
  <NoLayout>
    <div className="request-invite-form-outer-container">
      <RequestInviteForm />
    </div>
  </NoLayout>
)

export default RequestInvitePage
